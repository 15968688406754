.subscription-area {
  max-width: 800px;
  margin: auto;
  width:  calc(100% - 15px);
  border: 1px solid #888888;
  border-radius: 15px;
  background: #fff;
}
.payment-btn{
  display: flex;
   align-items: center;
   flex-direction: column;
}
.payment-btn button+button{
margin-top: 5px;
}

.subscription-area h2 {
  font-size: 24px;
  font-weight: 500;
}
.plan-details h3 {
  font-size: 20px;
  font-weight: 500;
}

.plan-details h4 {
  font-size: 18px;
  font-weight: 400;
}

.plan-desc h3{
 font-size: 20px;
  font-weight: 500;
}
.plan-desc ul{
  padding-left: 25px;
  font-size: 16px;
  margin-bottom: 0px;
}
.plan-total strong{
  font-weight: 500;
  font-size: 20px;
}
.plan-total {
  font-size: 18px;
}

/* plansScreen */
.choose-plan h1{
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}
.choose-plan h2{
font-weight: 500;
text-align: center;
  font-size: 20px;
      margin-bottom: 1.5rem;
}
.choose-plan-box {
border-width: 2.7px 0.68px 0.68px 0.68px;
border-style: solid;
border-color: #362460;
border-radius: 20px;
}

.plan-heading {
text-align: center;
}
.plan-heading h3 {
font-size: 22px;
font-weight: 600;
}
.plan-heading p {
font-size: 12px;
font-weight: 500;
line-height: 18px;
}
.plan-price {
text-align: center;
padding: 8px 5px;
background: #EBE9EF;
border-top: 1px solid #362460;
border-bottom: 1px solid #362460;
font-size: 18px;
font-weight: 500;
}

.plan-content h4{
font-size: 17px;
}

.plan-content ul{
font-size: 15px;

}
.plan-content ul li:before {
content: "";
background-image: url('../../assests/images/plan-check.png');
width: 19px;
height: 18px;
left: 0px;
position: absolute;
top: 50%;
transform: translateY(-50%);
}
.plan-content ul li {
padding-left: 25px;
position: relative;
line-height: 15px;
}
.plan-content ul li:not(:last-child){
margin-bottom: 12px;
}

.plan-btn {
border-radius: 6px;
background: #362460;
padding: 8px 30px;
color: #fff;
font-size: 16px;
font-weight: 500;
border: 0px;
outline: none;
}

/* Error Icon */
.error-icon {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

/* Success Icon */
.success-icon {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.plan-div{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 10px;
}

.plan-content-div{
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-div{
  display: flex;
  width: 105px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #0076F8;
  background: #FFF;
  outline: none;
}

.content-text{
  color: #1E1E1E;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.32px;
}

.learn-more{
  color: #362460;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.32px;
}

.plan-btn.loading {
  background-color: #ddd; 
  cursor: not-allowed; 
}


.price-box-css {
  background: #001f3f;
  border-radius: 8px;
  padding: 25px;
  text-align: center;
  max-width: 418px;
  width: 100%;
  margin: auto;
}


.sff-btn {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background: #5EA6F8;
  display: inline-block;
  text-decoration: none;
  padding: 8px 25px;
  border: 0px;
  border-radius: 8px;
}

.price-box-css h3 {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  margin: 10px 0px 15px 0px;
  line-height: 38px;
}

.price-box-css h4 {
  color: #57A5FB;
  font-size: 55px;
  font-weight: 600;
  margin-bottom: 15px;
}

.price-box-css h4 sup {
  font-size: 30px;
  font-weight: 500;
  left: 10px;
  top: -25px;
}

.customizee-opt {
  margin-bottom: 15px;
}

.customizee-opt ul {
  display: inline-block;
}

.customizee-opt ul li {
  display: flex;
  align-items: center;
}

.customizee-opt ul li:not(:last-child) {
  margin-bottom: 10px;
}

.customizee-label {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  order: 1;
  margin-left: 20px;
}

.customizee-num select {
  border-radius: 8px;
  border: 1px solid #0076F8;
  padding: 6px 10px;
  width: 100px;
  outline: none;
  box-shadow: none;
}

.customizee-label:before {
  content: "";
  width: 7px;
  height: 7px;
  margin-right: 10px;
  background: #fff;
  display: inline-block;
  border-radius: 20px;
}