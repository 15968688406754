.working-inner-two {
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.current-working-task-two-section {
  width: -webkit-fill-available;
}

.working-inner-two-viewmore {
  padding: 20px;
  border-radius: 20px 20px 20px 20px;
}

.search-group-two {
  /* border-bottom: 1px solid #CECECE; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.view-line {
  border-bottom: 1px solid #cecece;
}

.search-group-two form {
  position: relative;
  width: 100%;
}

.search-group-two form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.search-group-two form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.working-task-two h2 {
  font-size: 20px;
  font-weight: 600;
  color: #362460;
}

.task-btn button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.task-btn button+button {
  margin-left: 15px;
}

.current-notification h3 {
  font-size: 16px;
  color: #57a5fb;
  font-weight: 600;
  margin-bottom: 0px;
}

.current-tasks-data-two p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.current-tasks-data-two-viewmore p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.current-notification-details p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.current-tasks-data-two {
  height: 200px;
  overflow-y: auto;
}

.current-tasks-data-two::-webkit-scrollbar {
  width: 5px;
}

.current-tasks-data-two::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.current-tasks-data-two::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.current-tasks-data-two-viewmore {
  height: 315px;
  overflow-y: auto;
}

.current-tasks-data-two-viewmore::-webkit-scrollbar {
  width: 5px;
}

.current-tasks-data-two-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.current-tasks-data-two-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.edit-task-action-two {
  border: 0px;
  padding: 0px;
  background: transparent;
}

.current-tasks-data-two label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.current-tasks-data-two .row:not(:last-child) {
  margin-bottom: 5px;
}

.current-tasks-label-two h3 {
  font-size: 16px;
  color: #57a5fb;
  font-weight: 600;
  margin-bottom: 0px;
}

.task-progress-label-two {
  display: inline-block !important;
}

.assignee-label-two {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assignee-label-two span {
  border-radius: 20px;
  width: 32px;
  color: #fff;
  height: 32px;
  background: #959595;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assignee-label-two span:not(:last-child) {
  margin-right: 10px;
}

.upload-file-project {
  border-radius: 8px;
  border: 2px dashed #d3d7da;
  background: #fff;
  padding: 15px;
  min-height: 140px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.box-file-hidded {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-txt-project {
  font-size: 16px;
  color: #29353d;
  font-weight: 400;
  margin-bottom: 5px;
}

.upload-file-project label {
  font-size: 18px;
  color: #1d68a5;
  margin-bottom: 0px;
  cursor: pointer;
  display: inline;
  font-weight: 700;
}

.upload-file-project p {
  color: #29353d;
  font-size: 12px;
}

.modal-body .write-project-name input {
  font-size: 20px !important;
  border: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 0px !important;
}

.add-milestone {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
  padding-bottom: 5px;
}

.add-milestone label {
  margin-bottom: 0px;
}

.add-milestone button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.modal-body .milestone-input {
  font-size: 18px !important;
  border: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* task pop up css */

.grey-popup .modal-content {
  background: #d7d3df !important;
  padding-bottom: 10px;
}

.grey-popup .modal-header {
  border: 0px;
}

.modal-header .breadcrumb a {
  color: #1e1e1e;
  text-decoration: none;
}

.modal-header .breadcrumb li {
  color: #1e1e1e !important;
  font-size: 18px;
  font-weight: 500;
}

.modal-header .breadcrumb-item+.breadcrumb-item::before {
  background: url("../../assests/images/arrow-right.svg");
  background-repeat: no-repeat;
  content: "" !important;
  width: 8px;
  height: 12px;
  padding: 0px !important;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
}

.modal-header .breadcrumb-item+.breadcrumb-item {
  padding-left: 30px;
}

.task-ul li {
  display: flex;
  align-items: center;
  position: relative;
}

.task-ul>li:not(:last-child) {
  margin-bottom: 5px;
}

.task-label {
  color: #878787;
  font-size: 12px;
  max-width: 70px;
  width: 100%;
}

.task-assignto-label {
  color: #878787;
  font-size: 12px;
  max-width: 77px;
  width: 100%;
  margin-right: 5px;
}

.user-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 14px;
  max-width: 14px;
}

.user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 14px;
  max-width: 14px;
}

.task-status-label {
  color: #878787;
  font-size: 12px;
  max-width: 40px;
  width: 100%;
}

.task-info {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
}

.task-pt .dropdown-toggle-split {
  color: #cecece !important;
  border: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-pt .dropdown-toggle::after {
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
}

.task-pt .btn-color {
  border: 0px !important;
  border-radius: 5px !important;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.task-info li:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.task-pt .dropdown-item {
  font-size: 12px;
  color: #1e1e1e;
  padding: 7px 0px;
  line-height: 12px;
  background: transparent;
}

.task-pt .dropdown-menu {
  min-width: auto !important;
  padding: 5px 10px;
  left: -38px !important;
}

.task-head {
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 25px;
}

.task-head-left .pending-tsk,
.atc {
  border-radius: 5px;
  background: #f0a83a;
  padding: 5px 15px;
  color: #000000;
  font-weight: 600;
  border: none;
}

.arh {
  border-radius: 5px;
  background: #f0a83a;
  padding: 5px 7px 5px 7px;
  color: #fff;
}

.mark-tick {
  padding: 5px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background: #57A5FB;
  border: none;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
}

.mark-tick img {
  margin-right: 7px;
}

.tt-track {}

.head-tt {
  color: #878787;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.head-at {
  color: #1e1e1e;
  font-weight: 600;
  background: transparent;
}

.head-at button {
  border: none;
  background: transparent;
}

.attach-icon {
  border: none;
  background: transparent;
}

.head-at svg {
  margin-right: 5px;
}

.assignee-icon .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.assignee-icon .dropdown-toggle div+div {
  margin-left: -15px;
}

.assignee-icon .dropdown-toggle>*:hover {
  z-index: 9;
}

.assignee-icon .dropdown-toggle::after {
  display: none;
}

.task-body {
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 25px;
}

.border-assignee {
  border: 1px dashed #878787;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
}

.bg-assignee {
  border-radius: 50%;
  /*background: #F13A3A;*/
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  /* position: relative; */
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.bg-assignee button,
.border-assignee button {
  background: #d7d3df;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border: 0px;
  opacity: 0.8
  /* align-items: center; */
  /* justify-content: center; */
}

.bg-assignee button img,
.border-assignee button img {
  width: 8px;
}

.bg-assignee:hover button,
.border-assignee:hover button {
  display: flex !important;
}

.chat-area {
  padding: 20px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 0px -2px 10px 0px rgba(54, 36, 96, 0.1);
}

.write-chat {
  display: flex;
  align-items: center;
}

.chat-inner {
  position: relative;
  width: 100%;
}

.chat-inner .form-control {
  padding-right: 50px;
}

.atc-dropdown .dropdown-toggle::after {
  display: none;
}

.atc-dropdown .dropdown-menu li {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.atc-dropdown .dropdown-menu li label {
  font-weight: 400;
  padding: 7px 0px;
  cursor: pointer;
  color: #8f8f8f !important;
  font-size: 14px !important;
  margin-bottom: 0px;
}

.atc-dropdown .dropdown-menu {
  padding: 5px 18px;
  white-space: nowrap;
}

.atc-dropdown .dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.chat-attachment {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 41px;
  z-index: 9;
  border: none;
  background: transparent;
}

.send-chat {
  margin-left: 10px;
  border: none;
  background: transparent;
}

.assignee-icon .dropdown-menu {
  padding: 10px 15px 10px 15px;
}

.short-an {
  border-radius: 50%;
  background: #57A5FB;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff !important;
  font-weight: 600;
  margin-right: 8px;
}

.assignee-icon .dropdown-item {
  font-size: 14px;
  color: #1e1e1e !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  background: transparent !important;
}

.assign-users {
  margin-bottom: 10px;
}

.assign-users input {
  border: 0px !important;
  border-bottom: 1px solid #cecece !important;
  padding: 7px 10px 7px 25px !important;
  font-weight: 400 !important;
  border-radius: 0px !important;
}

.assign-users form {
  position: relative;
  width: 100%;
}

.assign-users form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
}

.attachments-area {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cecece;
  padding-bottom: 1.5rem;
}

.attachments-area>div {
  margin-right: 15px;
}

.attachment-image img {
  height: 50px;
  border-radius: 10px;
}

.attachment-task {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #3ebb11;
  padding: 5px;
  height: 64px;
}

.file-dw {
  color: #878787;
  /*font-size: 11px;*/
}

.file-dw a {
  color: #1e1e1e;
  /*font-size: 11px;;*/
  text-decoration: none;
}

.file-dw a:hover {
  text-decoration: underline;
}

.attachment-name {
  font-size: 11px;
  margin-left: 10px;
}

.attached-name {
  margin-bottom: 3px;
}

.add-more-attach {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  border: 2px dashed #878787;
}

.task-created-inner {
  display: flex;
  align-items: flex-start;
  /* padding-top: 1.5rem;
     border-top: 1px solid #CECECE;*/
}

.task-created-inner:not(:last-child) {
  margin-bottom: 15px;
}

.task-created-inner-logs {
  display: flex;
  align-items: flex-start;
  /* padding-top: 1.5rem;
     border-top: 1px solid #CECECE;*/
}

.task-created-inne-logs:not(:last-child) {
  margin-bottom: 5px;
}

.short-label {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  width: 100%;
  margin-right: 10px;
}

.short-userlog-label {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 23px;
  height: 23px;
  font-size: 10px;
  font-weight: 400;
  border-radius: 50%;
  width: 100%;
  margin-right: 5px;
}

.full-label {
  font-size: 14px !important;
}

.chat-bg {
  padding: 20px;
  border-radius: 20px;
  background: #f1f1f1;
  max-height: 220px;
  overflow-y: scroll;
}

.write-task-chat {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 0px -2px 10px 0px rgba(54, 36, 96, 0.1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.task-chat-inner {
  position: relative;
  width: 100%;
}

.task-chat-attachment {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 41px;
  z-index: 9;
  border: none;
  background: transparent;
}

.edit-chat .dropdown-toggle {
  width: 20px;
  text-align: right;
}

.edit-chat .dropdown-toggle::after {
  display: none;
}

.edit-chat .dropdown-item {
  background: transparent !important;
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 10px;
}

.edit-chat .dropdown-menu {
  /*    width: 110px !important;*/
  min-width: auto;
}

.edit-chat button {
  border: none;
}

.edit-task-viewmore {
  z-index: 99999;
}

.add-task-viewmore {
  z-index: 99999;
}

/* Time Tracking Popup CSS */
.task-list {
  height: 110px;
  overflow-y: scroll;
  outline: none;
}

.task-list ul {
  list-style-type: decimal;
  padding-left: 15px;
  padding-right: 10px;
}

.f-500 {
  font-weight: 500;
}

.task-list ul li:not(:last-child) {
  margin-bottom: 5px;
}

.total-time {
  font-weight: 600;
  color: #3ca50b;
}

.time-dots {
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  color: #1e1e1e;
  opacity: 0.5;
  margin-top: 20px;
}

.time-panel button {
  font-weight: 600;
  color: #0076f8;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.time-panel button img {
  margin-right: 8px;
}

.time-desc,
.bottom-time {
  padding: 10px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.12);
}

.time-desc textarea {
  border-radius: 0px;
  resize: none;
}

.manual-input>div:not(:last-child) {
  margin-right: 5px;
}

.manual-time .bg-btn {
  padding: 5px 25px;
}

.manual-input input {
  text-align: center;
  padding: 9px 5px !important;
}

.time-tracking-modal .modal-body {
  padding: 20px 0px 30px 0px;
}

.timeTrackingDesc {
  border: 0 !important;
  padding: 0 !important;
  outline: none !important;
  width: auto !important;
}

.editButton {
  position: relative;
  top: -3px;
}

.task-time-track {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.timeTrackinIndex {
  z-index: 99999;
}

.save-btn {
  background: transparent;
  padding: 6px 10px;
  margin-left: 15px;
  border-radius: 5px;
  /* outline: none !important; */
  border: 1px solid #3ebb11;
  color: #3ebb11;
  font-weight: 500;
}

.clickup-users{
  overflow-y: auto;
  max-height: 410px;
}

@media screen and (max-width: 768px) {

  .working-inner-two {
    border-radius: 20px;
  }


  .current-working-task-two h2 {
    font-size: 15px;
  }

  /* .tasks-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 321px;
  } */

  .current-tasks-data-two>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .tasks-row>div:last-child {
    margin-bottom: 15px;
  }

  .current-tasks-data-two .row {
    margin-right: 15px;
  }

  .current-tasks-data-two-viewmore>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .current-tasks-data-two-viewmore .row {
    margin-right: 15px;
  }

  .tasks-row div>p {
    font-size: 15px;
  }

  .current-tasks-data-two .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .current-tasks-data-two-viewmore .row:not(:last-child) {
    margin-bottom: 15px;
  }


  .tasks-row>div:nth-child(-n+6) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }

  .tasks-row>div:nth-child(1)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .edit-task-action-two>img {
    width: 20px;
  }

  .edit-task-action-two>img {
    width: 15px;
    margin-left: 10px;
  }

  .tasks-row>div:last-child {
    background: #57a5fb;
    color: black;
    border-radius: 5px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    width: 40px;
    height: 30px;
    /* border-bottom: 2px solid #000; */
    margin-left: 2px;
    margin-top: 10px;
  }

  /* .tasks-row>div:last-child::after {
    content: 'Edit';
    margin-left: 15px;
  } */

  .modal-header .breadcrumb li {
    font-size: 14px;
    /* font-weight: 600; */
  }

  .modal-header .breadcrumb-item+.breadcrumb-item {
    padding-left: 20px;
  }


  .modal-header .breadcrumb-item+.breadcrumb-item::before {
    background: url("../../assests/images/arrow-right.svg");
    background-repeat: no-repeat;
    content: "" !important;
    width: 8px;
    height: 12px;
    padding: 0px !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  }

  .task-status-label {
    display: none;
    /* color: black;
    font-size: 17px;
    max-width: 172px;
    width: 100%; */
  }

  .task-head-left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .new-task-mobile{
    width: 100%;
  }
  .new-task-mobile .css-13cymwt-control{
    width: 100%;
  }

  .task-head-left .css-b62m3t-container {
    width: 100% !important;
  }
  .task-head {
    flex-direction: column;
    padding: 10px;
  }

  .mark-tick{
    margin-right: 10px;
    margin-left: 10px;
    padding: 2px 10px;
    font-weight: 400;
  }

  .btn-close .img-new {
    max-width: auto !important;
  }

  .css-13cymwt-control {
    margin-top: 10px;
    width: 200px;
    /* margin-left: 7px; */
  }

  .atc-dropdown .css-13cymwt-control {
    width: 200px;
  }

  #modal-header {
    display: flex;
    flex-direction: column;
  }
  .modal-header nav {
    flex: 0 0 auto;
    margin-bottom: 5px;
  }
  .modal-header {
    /* flex-direction: column; */
  }

  .new-task-header {
    flex-direction: column;
  }
  .new-task-content {
    padding: 0px 10px;
  }

  .mark-mobile {
    width: 100%;
    justify-content: space-between;
  }

  .task-head {
    flex-direction: column;
  }

  nav {
    width: -webkit-fill-available;
    margin-top: 10px;
  }

  nav ol {
    margin-left: 10px;
  }


  .task-head-right {
    margin-left: 0px;
    margin-top: 12px;
    justify-content: space-between;
    width: 100%;
  }

  .tt-track {

  }
  .mark-complete-mobile{
    font-size: 10px;
    padding: 6px 10px;
  }

  .task-btn .add-icon-two>img {
    width: 30px;
  }

  .task-btn .task-expand>img {
    width: 30px;
  }

  .tasks-row>div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 90px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(1) {
    display: flex;
  }

  .tasks-row>div:nth-child(2)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(2) {
    display: flex;
  }

  .tasks-row>div:nth-child(3)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 123px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(3) {
    display: flex;
  }

  .tasks-row>div:nth-child(4)::before {
    content: 'Assigned To: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 63px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(4) {
    display: flex;
  }

  .tasks-row>div:nth-child(5)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 80px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(5) {
    display: flex;
  }

  .tasks-row>div:nth-child(6)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 111px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(6) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 87px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(1) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(2)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 72px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(2) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(3)::before {
    content: 'Tasks Assigned: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 120px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(3) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(4)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(4) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(5)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 75px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(5) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(6)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 105px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(6) {
    display: flex;
  }



  .search-group-two form {
    position: relative;
    width: 75%;
  }
  .search-group-two form input {
    font-size: 12px;
  }
  .search-group-two form button{
    width: 30px;
    height: 33px;
  }

  .current-tasks-data-two {
    height: 300px;
  }

  .tasks-row>div:before{
    max-width: 140px;
    width: 100%;
  }

  .tasks-row>div{
    display: flex;
  }

  .time-panel button {
    font-size: 12px;
    font-weight: 500;
  }

  .time-panel img {
    width: 30px !important;
  }
  .total-time {
    font-weight: 400;
  }
  .editButton img{
    width: 20px !important;
  }
  .manual-time .bg-btn {
    width: 100%;
    font-size: 15px;
    padding: 10px 25px;
  }
  .time-dots{
    display: none;
  }

}

@media screen and (max-width: 575.98px) {
  .working-inner-two {
    padding: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* .current-tasks-label-two>div {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1.2fr 0.9fr 0.9fr;
  }

  .current-tasks-data-two>div {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1.2fr 0.9fr 0.9fr;
  } */

  .tasks-row>div:last-child {
    margin-bottom: 15px;
  }

  .current-tasks-data-two .row {
    margin-right: 15px;
  }

  .current-tasks-data-two-viewmore>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .current-tasks-data-two-viewmore .row {
    margin-right: 15px;
  }

  .tasks-row div>p {
    font-size: 15px;
  }

  .current-tasks-data-two .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .current-tasks-data-two-viewmore .row:not(:last-child) {
    margin-bottom: 15px;
  }


  .tasks-row>div:nth-child(-n+6) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }

  .tasks-row>div:nth-child(1)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .edit-task-action-two>img {
    width: 20px;
  }

  .edit-task-action-two>img {
    width: 15px;
    margin-left: 10px;
  }

  .tasks-row>div:last-child {
    background: #7D9CFF;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 40px;
    height: 30px;
    border-bottom: 2px solid #000;
    margin-left: 2px;
    margin-top: 10px;
  }

  /* .tasks-row>div:last-child::after {
    content: 'Edit';
    margin-left: 15px;
  } */

  .modal-header .breadcrumb li {
    font-size: 14px;
    /* font-weight: 600; */
  }

  .modal-header .breadcrumb-item+.breadcrumb-item {
    padding-left: 20px;
  }


  /* .modal-header .breadcrumb-item+.breadcrumb-item::before {
    background: url("../../assests/images/arrow-right.svg");
    background-repeat: no-repeat;
    content: "" !important;
    width: 8px;
    height: 12px;
    padding: 0px !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  } */

  .task-status-label {
    display: none;
    /* color: black;
    font-size: 17px;
    max-width: 172px;
    width: 100%; */
  }

  /* .task-head-left {
    display: flex;
    flex-direction: column;
    width: 100%;
  } */

  /* .new-task-mobile{
    width: 100%;
  } */
  /* .new-task-mobile .css-13cymwt-control{
    width: 100%;
  } */

  .task-head-left .css-b62m3t-container {
    width: 100% !important;
  }
  .task-head {
    flex-direction: column;
    padding: 10px;
  }

  .mark-tick{
    margin-right: 10px;
    margin-left: 10px;
    padding: 2px 10px;
    font-weight: 400;
  }

  .btn-close .img-new {
    max-width: auto !important;
  }

  .css-13cymwt-control {
    margin-top: 10px;
    width: 200px;
    /* margin-left: 7px; */
  }

  .atc-dropdown .css-13cymwt-control {
    width: 200px;
  }

  #modal-header {
    display: flex;
    flex-direction: column;
  }
  .modal-header nav {
    flex: 0 0 auto;
    margin-bottom: 5px;
  }
  .modal-header {
    /* flex-direction: column; */
  }

  .new-task-header {
    flex-direction: column;
  }
  .new-task-content {
    padding: 0px 10px;
  }

  .mark-mobile {
    width: 100%;
    justify-content: space-between;
  }

  .task-head {
    flex-direction: column;
  }

  nav {
    width: -webkit-fill-available;
    margin-top: 10px;
  }

  nav ol {
    margin-left: 10px;
  }


  .task-head-right {
    margin-left: 0px;
    margin-top: 12px;
    justify-content: space-between;
    width: 100%;
  }

  .tt-track {

  }
  .mark-complete-mobile{
    font-size: 10px;
    padding: 6px 10px;
  }

  .task-btn .add-icon-two>img {
    width: 30px;
  }

  .task-btn .task-expand>img {
    width: 30px;
  }

  .tasks-row>div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 90px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(1) {
    display: flex;
  }

  .tasks-row>div:nth-child(2)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(2) {
    display: flex;
  }

  .tasks-row>div:nth-child(3)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 123px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(3) {
    display: flex;
  }

  .tasks-row>div:nth-child(4)::before {
    content: 'Assigned To: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 63px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(4) {
    display: flex;
  }

  .tasks-row>div:nth-child(5)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 80px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(5) {
    display: flex;
  }

  .tasks-row>div:nth-child(6)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 111px;
    color: #7B838A;
  }

  .tasks-row>div:nth-child(6) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 87px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(1) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(2)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 72px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(2) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(3)::before {
    content: 'Tasks Assigned: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 120px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(3) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(4)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(4) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(5)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 75px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(5) {
    display: flex;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(6)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 105px;
    color: #7B838A;
  }

  .current-tasks-data-two-viewmore .tasks-row>div:nth-child(6) {
    display: flex;
  }



  .search-group-two form {
    position: relative;
    width: 75%;
  }
  .search-group-two form input {
    font-size: 12px;
  }
  .search-group-two form button{
    width: 30px;
    height: 33px;
  }

  .current-tasks-data-two {
    height: 300px;
  }

  .tasks-row>div:before{
    max-width: 360px;
    width: 100%;
  }

  .tasks-row>div{
    display: flex;
  }
}

/* .comment-log-tab ul li button {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  color: #362460 !important;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  border: 0px;
  border-radius: 140px;


} */

.comment-log-tab ul li button {
  padding: 0px 5px;
  color: #362460 !important;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  border-bottom: 2px solid transparent;
}

.comment-log-tab ul li button.active {
  /* background: rgba(0, 82, 204, 0.12); */
  border-bottom: 2px solid #7257ae;
}

.mark-done {
  /* width: 100%; */
  justify-content: space-between;
}

.text-limit-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}