.reports-inner {
    border-radius: 20px 20px 0px 0px;
    padding: 20px;
}

.reports-inner-viewmore {
    border-radius: 20px 20px 20px 20px;
    padding: 20px;
    height: 520px;
    /* width: max-content; */
}

.filter-data select,
.filter-data input {
    color: #362460 !important;
    font-weight: 500 !important;
}

.analytics-info {
    border-bottom: 1px solid #CECECE;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.analytics-info ul li:not(:last-child) {
    margin-right: 5px;
}

.analytics-info ul li button {
    padding: 0px 5px;
    color: #362460 !important;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    border-bottom: 2px solid transparent;
}

.analytics-info ul li button.active {
    border-bottom: 2px solid #7257ae;
}

.reports-data .row {
    border-bottom: 1px solid rgba(30, 30, 30, 0.2);
    padding: 7px 0px;
}

.reports-data {
    height: 178.5px;
    overflow-y: auto;
}
.reports-data-viewmore {
    height: 164.5px;
    overflow-y: auto;
}

.chat-data {
    height: 230px;
    overflow-y: auto;
}
.chat-data-viewmore {
    height: 230px;
    overflow-y: auto;
}

.custom-button {
    /* margin-top: 10px; */
    margin-left: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.custom-button:hover {
    background-color: #0056b3;
}

.analytics-info ul {
    display: flex;
    align-items: center;
}

.analytics-info .expand-button {
    background: transparent !important;
    padding: 0px;
    outline: none !important;
    border: 0px;
    box-shadow: none !important;
}

.add-project-label {
    color: #1E1E1E;
    font-weight: 550;
    font-size: 15px;
    margin-right: 10px;
}

.react-datepicker__input-container .date-picker {
    padding: 5.1px 10px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
}

.react-datepicker-wrapper {
    width: 100%;
    max-width: 300px;
}
.react-datepicker-wrapper input {
    width: 100%;
}

.css-13cymwt-control{
    margin-top: 0px;
}

@media screen and (max-width: 760px) {
    .reports-inner{
        border-radius: 20px;
    }
    .add-icon-tickets>img {
        width: 15px;
    }

    /* .modal-body > div:nth-child(3) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(4) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(6) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(7) .css-6ryl5x-control {
      width: 320px;
    } */

    /* .form-control {
      font-size: 10px;
    } */

    .tickets-tab-search form input {
        font-size: 12px;
    }

    .tickets-tab-search form button {
        width: 28px;
        height: 28px;
    }

    .chat-tab ul li button {
        font-size: 15px;
    }

    .tickets-tab-search .add-icon-tickets {
        padding: 0px 7px;
    }

    .reports-data .row>div:first-child {
        display: none;
    }

    .reports-data .row:not(:last-child) {
        border-bottom: 1px solid #7B838A;
    }

    .reports-data .row {
        padding-right: 10px;
    }




    .reports-data .row>div:nth-child(-n+7) {
        background-color: #dbe7ff;
        border: 2px solid #57a5fb;
        border-radius: 10px;
        margin: 5px 0;
        padding: 0 5px 0 10px;
        height: auto;
    }

    .reports-data .row>div:nth-child(2)::before {
        content: 'Project: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 65px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(2) {
        display: flex;
    }

    .reports-data .row>div:nth-child(3)::before {
        content: 'Task Name: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 75px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(3) {
        display: flex;
    }

    .reports-data .row>div:nth-child(4)::before {
        content: 'Users: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 56px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(4) {
        display: flex;
    }

    .reports-data .row>div:nth-child(5)::before {
        content: 'Time Tracked: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 60px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(5) {
        display: flex;
    }

    .reports-data .row>div:nth-child(6)::before {
        content: 'Time Period: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 89px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(6) {
        display: flex;
    }

    .reports-data .row>div:nth-child(7)::before {
        content: 'Attachment: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 90px;
        color: #7B838A;
    }

    .reports-data .row>div:nth-child(7) {
        display: flex;
    }

    .reports-data .row>div:nth-child(8) {
        background: white;
        color: #57A5FB;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        width: 50px;
        height: 40px;
        border-bottom: 2px solid #57A5FB;
        margin-left: 2px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .reports-data .row>div:nth-child(8) img {
        width: 30px !important;
    }

    .custom-button {
        margin-top: 10px;
        margin-left: 10px;
        padding: 8px 16px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .custom-button:hover {
        background-color: #0056b3;
    }
    .custom-mobile-view{
        margin-left: 0px;
    }

    .mobile-filter{
        justify-content: space-evenly !important;
    }

    .users-select .css-b62m3t-container .css-13cymwt-control{
        width: 135px;
    }
    .project-select .css-b62m3t-container .css-13cymwt-control{
        width: 135px;
    }

    .reports-row-data>div:before{
        max-width: 140px;
        width: 100%;
      }
    
      .reports-row-data>div{
        display: flex;
      }
   
    /* .user-select .css-b62m3t-container{
        width: auto !important;
    } */


}