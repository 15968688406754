.upcoming-appointments {
  border-radius: 20px;
  background: #DBE7FF;
  padding: 20px 0px 0px 0px;
  border-top: 8px solid #57A5FB;
  border-right: 2px solid #57A5FB;
  border-bottom: 2px solid #57A5FB;
  border-left: 2px solid #57A5FB;
}

.appointments-heading {
  border-bottom: 1px solid #A899CC;
  padding: 0px 20px 10px 20px;
  margin-bottom: 15px;
}

.apt-col .button-upcoming{
  border-radius: 0px 0px 0px 20px;
}

.appointments-heading-viewmore-css {
  /* border-bottom: 1px solid #A899CC; */
  padding: 0px 20px 10px 20px;
  margin-bottom: 15px;
}

.appointments-heading .add-icon {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.appointments-heading button+button {
  margin-left: 15px;
}

.upcoming-appointments h2 {
  font-size: 20px;
  font-weight: 600;
}

.apt-confirm {
  display: flex;
  align-items: center;
}

.apt-confirm button+button {
  margin-left: 5px;
}

.apt-confirm button {
  border-radius: 4px;
  padding: 5px 8px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  outline: none;
  border: 0px;
}

/* .apt-confirm button img {
  margin-right: 5px;
} */

.apt-yes {
  background: linear-gradient(0deg, #289659 0%, #289659 100%), #28B667;
}

.apt-no {
  /* background: none; */
  background: #F13A3A;
}

.appointments-list {
  padding: 0px 20px;
  /* height: 195px; */
  overflow-y: auto;
}
.appointments-list::-webkit-scrollbar {
  width: 5px; 
}

.appointments-list::-webkit-scrollbar-track {
  background-color: #fbfbfb; 
}

.appointments-list::-webkit-scrollbar-thumb {
  background-color: #57A5FB; 
  height: auto; 
}

.viewmore {
  /* padding: 0px 20px; */
  height: 195px;
  /* overflow-y: auto; */

}
.viewmore-popup {
  /* padding: 0px 20px; */
  height: 430px;
  /* overflow-y: auto; */
}

.apt-col {
  position: relative;
}

.apt-col:after {
  content: "";
  background: linear-gradient(180deg, rgba(168, 153, 204, 0.00) 0%, #A899CC 53.96%, rgba(168, 153, 204, 0.00) 100%);
  width: 1px;
  height: calc(100% - 33.19px);
  position: absolute;
  right: 12px;
  top: 0;
}

.appointments-list li {
  position: relative;
  background: #fff;
  border-radius: 0px 20px 0px 0px;
  padding: 10px 10px 10px 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.appointments-list li h3 {
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 600;
}

.appointments-list li span {
  font-size: 11px;
}

.appointments-list li:not(:last-child) {
  /*margin-bottom: 10px;*/
}

.appointments-list li:before {
  content: "";
  width: 5px;
  height: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 0px;
}
.appointments-list li.accepted:before {
  /* width: 7px; */
  background-color: #4caf50; /* Change to green color */
}
.appointments-list li.reschedule:before {
  background-color: #4caf50; /* Change to green color */
}
.appointments-list li a {
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.appointments-modal .modal-content {
  padding: 0px 20px 20px 20px;
  background: #E0EEFE !important;
}

.event-btn .save-me {
  border-radius: 5px;
  background: #57A5FB;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
  padding: 5px 20px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: 0px !important;
}

.appointments-modal .modal-body {
  padding: 20px;
  border-radius: 10px;
}

.meeting-link {
  position: relative;
}

.meeting-link button {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.appointments-modal .modal-header {
  border: 0px;
}

.when-event {
  display: flex;
  align-items: center;
}

.when-event>*:not(:last-child) {
  margin-right: 10px;
}

.when-event input,
.which-day button {
  background: #CECECE !important;
  border: 0px !important;
  padding-left: 3px !important;
  margin: 0px;
  border-radius: 5px !important;
  font-size: 12px !important;
  width: 150px;
  height: 30px;
}

.when-event input::-ms-input-placeholder {
  font-size: 11px !important;
}

.when-event input::placeholder {
  font-size: 11px !important;
}

.event-time {
  display: flex;
  align-items: center;
}

.time-zone,
.to-text {
  font-size: 11px !important;
}

.to-text {
  padding: 0px 5px;
}

.event-assignee {
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #CECECE;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px rgba(30, 30, 30, 0.20);
}

.event-users {
  margin-bottom: 10px;
}

.event-users form {
  position: relative;
  width: 100%;
}

.event-users input {
  border: 0px !important;
  border-bottom: 1px solid #CECECE !important;
  padding: 7px 10px 7px 25px !important;
  font-weight: 400 !important;
  border-radius: 0px !important;
}

.event-users form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 0;
}

.event-assignee .dropdown-item {
  font-size: 14px;
  color: #1E1E1E !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  background: transparent !important;
}

.short-event-an {
  border-radius: 50%;
  background: #57A5FB;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff !important;
  font-weight: 600;
  margin-right: 8px;
  position: relative;
}

.short-event-an:after {
  content: "";
  background: url("../../assests/images/green-tick.svg");
  width: 10px;
  height: 11px;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
}

.event-assignee ul li.active .short-event-an:after {
  display: block;
}

.event-assignee ul {
  height: 221px;
  overflow-y: auto;
}

.all-days label {
  color: #878787;
  font-size: 13px;
}

.which-day .dropdown-toggle:after {
  display: none;
}

.which-day .dropdown-menu {
  padding: 2px 10px;
  white-space: nowrap;
  width: 100%;
}

.which-day .dropdown-menu li {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.which-day .dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #CECECE;
}

.which-day .dropdown-menu li label {
  font-weight: 400;
  padding: 5px 0px;
  cursor: pointer;
  color: #8F8F8F !important;
  font-size: 11px !important;
  margin-bottom: 0px;
}

.which-day .form-check-input {
  margin-right: 5px !important;
  width: 12px !important;
  height: 12px !important;
}

.all-days .form-check-input {
  width: 12px !important;
  height: 12px !important;
}

.decline-btn {
  border-radius: 10px;
background: #DE2C21;
  padding: 5px 30px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: 0px;
  min-width: 150px;
  text-align: center;
  margin-right: 10px;
}

.reschedule-btn {
     border-radius: 10px;
  background: #7257AE;
  padding: 5px 30px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: 0px;
  min-width: 150px;
  text-align: center;
  margin-right: 10px;

}
.calendar-css {
  background-color: transparent;
}

.react-calendar__tile {
  background: transparent;
  border: none;
  /* color: #fff; */
  cursor: default;
}

.react-calendar__navigation__label {
  background: transparent;
  border: none;
  /* color: #fff; */

}

.react-calendar__month-view__weekdays__weekday {
  /* color: #fff; */
  border: 0px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  cursor: default;
}

.react-calendar__navigation__arrow {
  background: transparent;
  border: none;
  /* color: #fff; */
  font-size: medium;

}
.selected-date {
  background-color: #FF5733; /* Change to your desired color */
  color: white; /* Change to your desired text color */
}
.accepted-text {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
}

.meeting-date-highlight abbr {
  color: white;
  z-index: 99;
  position: relative;
}
.meeting-date-highlight {
  position: relative;
}
.meeting-date-highlight:after {
  content: "";
  background: #4caf50;
  position: absolute;
  width: 25px;
  height: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  top: 50%;
}

.viewmore-reschedule {
  z-index: 99999;
}

.custom-time-input {
  position: relative;
  display: inline-block;
}

.time-input {
  width: 100px !important;
  height: 30px;
}

.css-b62m3t-container{
  width: 160px;
}

.setting-style{
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-49.6px, 26.4px, 0px);
}
.setting-style-list{
  background: transparent;
  font-size: 14px;
  padding: 0px;
  outline: none;
  border: 0px;
  color: #0076F8;
  display: block;
  width: 100%;
  text-align: left;
}

.google-sync-btn {
  border-radius: 5px;
  background: red;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
  padding: 5px 20px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: 0px !important;
  /* margin-right: auto !important; */
}

.google-synced-btn {
  border-radius: 5px;
  background: green;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
  padding: 5px 20px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: 0px !important;
  /* margin-right: auto; */
}

@media screen and (max-width: 760px) {

  .upcoming-appointments {
    width: -webkit-fill-available;
    margin-bottom: 1.5rem;
  }

  .upcoming-appointments h2 {
    font-size: 15px;
  }

  .apt-col .button-upcoming{
    border-radius: 0px 0px 20px 20px;
  }

  .add-icon > img {
    width: 30px;
  }

  .modal-title {
    font-size: 15px;
  }

  .modal-header {
    display: flex;
  }

  .event-btn .save-me {
    border-radius: 5px;
    background: #57A5FB;
    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
    padding: 5px 10px;
    color: #FFF;
    font-size: 10px;
    font-weight: 600;
    border: 0px !important;
  }

  .when-event {
    display: block;
    /* flex-direction: column; */
    /* justify-content: space-around; */
  }

  .when-event div:first-child {
    flex-grow: 3;
  }

  .mobile-align {
    display: flex;
    flex-direction: column;
  }

  .event-days {
    margin-top: 10px;
  }

  .event-days .all-days {
    margin-right: 14px !important;
    width: 120px;
    padding-left: 0 !important;
  }

  .event-days > div label {
    font-size: 14px;
    font-weight: 600;
  }

  .event-start-time input {
    width: 280px;
    text-align: center;
  }

  .when-event div:nth-child(2) {
    margin-top: 10px;
    /* margin-left: 10px; */
  }
  .when-event>*:not(:last-child) {
    margin-right: 0px;
  }

  .custom-time-input {
    position: relative;
    display: inline-block;
  }
  
  .time-input {
    width: auto !important;
    height: 30px;
    padding-left: 0 !important;
  }

  .recurring-event .css-13cymwt-control {
    width: 125px !important;
  }
  .span-mv{
    margin: 0px 10px;
  }

  .css-t3ipsp-control {
    margin-top: 10px;
  }
  .appointments-modal .modal-body {
    padding: 10px;
  }
  .react-datepicker-wrapper {
    max-width: 100% !important;
  }
  .mobile-newappoitment{
    display: flex;
  }
  .mobile-new-appt{
    flex-direction: column !important;
  }

  .view-appointment{
    padding: 0px;
    margin-top: 5px;
  }

  .view-appointment button{
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 400;
    /* width: 100px; */
    /* display: block; */
  }
  .reschedule-btn{
    min-width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .decline-btn {
    min-width: 50px;
    margin-top: 10px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 761px) and (max-width: 1080px ) {
  .mobile-align {
    display: flex;
    flex-direction: column;
  }

  .view-appointment{
    margin-top: 5px;
  }
  .reschedule-btn{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .event-days {
    margin-top: 10px;
  }
  .event-days .all-days {
    margin-right: 14px !important;
    width: 120px;
    padding-left: 0 !important;
  }

  .event-days > div label {
    font-size: 14px;
    font-weight: 600;
  }

  .all-user-list{
    width: 100%;
  }

}

.time-zone-info {
  font-style: italic;
  font-size: 12px;
}
