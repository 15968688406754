.items-name .row {
    margin-bottom: 15px;
}

.items-name {
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
}

.add-another button {
    border-radius: 0px;
    background: transparent;
    padding: 0px;
    color: #57A5FB;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border: 0px;
}


.invoice-area {
    border-radius: 20px 20px 0px 0px;
    background: #0076F8;
    padding: 20px;
    height: 100%;
}

.invoice-area-viewmore {
    border-radius: 20px 20px 20px 20px;
    background: #0076F8;
    padding: 20px;
    height: 520px;

}

.invoice-area h2 {
    font-size: 20px;
    font-weight: 600;
}

.invoice-heading {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.invoice-heading .invoice-add-icon {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
}

.invoice-btn button+button {
    margin-left: 15px;
}

.invoice-label h3 {
    font-size: 16px;
    color: #FFF;
    font-weight: 800;
    margin-bottom: 0px;
}

.invoice-label {
    margin-bottom: 15px;
}

.invoice-data {
    height: 250px;
    overflow-y: auto;
}

.invoice-data p {
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
}

.task-action {
    border: 0px;
    padding: 0px;
    background: transparent;
}

.invoice-data label {
    font-size: 16px;
    color: #FFF;
    font-weight: 800;
    display: block;
}

.invoice-data .row:not(:last-child) {
    margin-bottom: 10px;
}

.invoice-status {
    padding: 2px 11px;
    border-radius: 5px;
    background: #fff;
    color: #0076F8;
    display: inline-block;
}

.invoice-status img {
    margin-right: 5px;
}

.apt-confirm-invoice {
    /* display: flex; */
    align-items: center;
}

.apt-confirm-invoice button+button {
    margin-left: 5px;
}

.apt-confirm-invoice button {
    border-radius: 4px;
    padding: 5px 8px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    outline: none;
    border: 0px;
}

/* .apt-confirm button img {
    margin-right: 5px;
  } */

.apt-yes {
    background: linear-gradient(0deg, #289659 0%, #289659 100%), #28B667;
}

.apt-no {
    /* background: none; */
    background: #F13A3A;
}

.invoice-data::-webkit-scrollbar {
    width: 5px;
}

.invoice-data::-webkit-scrollbar-track {
    background-color: #57A5FB;
}

.invoice-data::-webkit-scrollbar-thumb {
    background-color: #fbfbfb;
    height: auto;
}


/* @media (min-width: 992px) {
    .invoice-data label {
        display: none !important;
    }
}

@media (max-width: 991.98px) {
    .invoice-data .row>* {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
} */

@media screen and (max-width: 760px) {
    .invoice-area {
        border-radius: 20px;
    }

    .add-icon-tickets>img {
        width: 15px;
    }

    /* .modal-body > div:nth-child(3) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(4) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(6) .css-6ryl5x-control {
      width: 320px;
    }
  
    .modal-body > div:nth-child(7) .css-6ryl5x-control {
      width: 320px;
    } */

    /* .form-control {
      font-size: 10px;
    } */

    .invoice-data label {
        display: none !important;
    }

    .tickets-tab-search form input {
        font-size: 10px;
    }


    .tickets-tab-search form button {
        width: 28px;
        height: 28px;
    }

    .chat-tab ul li button {
        font-size: 15px;
    }

    .tickets-tab-search .add-icon-tickets {
        padding: 5px 7px;
    }

    /* .invoice-data .row>div:first-child {
        display: none;
    } */

    .invoice-data .row:not(:last-child) {
        border-bottom: 1px solid white;
    }

    .invoice-data .row {
        /* padding-right: 10px; */
        margin-right: 15px;

    }

    .invoice-data .row>div:nth-child(-n+8) {
        background-color: #dbe7ff;
        border: 2px solid #57a5fb;
        border-radius: 10px;
        margin: 5px 0;
        padding: 0 5px 0 10px;
        height: auto;
    }

    .invoice-data .row>div:nth-child(n)>p {
        font-size: 14px;
        color: #1e1e1e;
        font-weight: 400;
        justify-content: center !important;
        align-items: center;
        margin-top: 2px;
    }

    .invoice-data .row>div:nth-child(1)::before {
        content: 'Invoice No: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 65px;
        color: #7B838A;
        /* margin-right: 10px; */
    }

    .invoice-data .row>div:nth-child(1) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(2)::before {
        content: 'Name: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 75px;
        color: #7B838A;
        /* margin-right: -15px; */
    }

    .invoice-data .row>div:nth-child(2) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(3)::before {
        content: 'Email: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 56px;
        color: #7B838A;
        /* margin-right: 10px; */
    }

    .invoice-data .row>div:nth-child(3) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(4)::before {
        content: 'Amount: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 60px;
        color: #7B838A;
        /* margin-right: 10px; */
    }

    .invoice-data .row>div:nth-child(4) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(5)::before {
        content: 'Due Date: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 89px;
        color: #7B838A;
        /* margin-right: -5px; */
    }

    .invoice-data .row>div:nth-child(5) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(6)::before {
        content: 'Paid On: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 90px;
        color: #7B838A;
        /* margin-right: -10px; */
    }

    .invoice-data .row>div:nth-child(6) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(7)::before {
        content: 'Invoice Status: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 90px;
        color: #7B838A;
    }

    .invoice-data .row>div:nth-child(7) {
        display: flex;
    }

    .invoice-data .row>div:nth-child(8)::before {
        content: 'Approval: ';
        font-weight: 600;
        font-size: 15px;
        min-width: 90px;
        color: #7B838A;
    }

    .invoice-data .row>div:nth-child(8) {
        display: flex;
    }

    /* .invoice-data .row>div:nth-child(8) {
        background: white;
        color: #57A5FB;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        width: 50px;
        height: 40px;
        border-bottom: 2px solid #57A5FB;
        margin-left: 2px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    } */

    .invoice-data .row>div:nth-child(9) {
        background: #7B838A;
        color: black;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        width: 40px;
        height: 30px;
        border-bottom: 2px solid #000;
        margin-left: 2px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .invoice-data .row>div:nth-child(10) img {
        width: 30px !important;
    }

    .invoice-btn {
        width: 25%;
    }

    .invoice-btn img {
        width: 30px !important;
        height: 30px !important;
    }

    .row-mobile>div:before {
        max-width: 120px;
        width: 100%;
    }

    .row-mobile>div {
        display: flex;
    }

    .invoice-data {
        height: 280px;
    }
}

@media screen and (max-width: 575.98px) {
    .invoice-area {
        padding: 10px;
    }
}

.col-lg-2 p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}