.hamburger {
    border: 0px;
    background: transparent;
    padding: 0px;
}

.profile-pic {
    margin-left: 25px;
}

.profile-pic img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: fill;
}

.head-time {
    color: #878787;
    font-weight: 500;
    line-height: normal;
}

.head-time-track {
    color: #1E1E1E;
    font-weight: 600;
}

.head-time-track svg {
    margin-right: 5px;
}

.task-notification {
    margin-left: 25px;
    position: relative;
}

.noti-col {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.bell-icon {
    margin-right: 10px;
    position: relative;
}
.bell-icon span{
    position: absolute;
    right: -3px;
    top: -3px;
    width: 17px;
    height: 17px;
    background-color: red;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
}

.noti-text {
    font-size: 13px;
    font-weight: 600;
    color: #0076F8;
}

.noti-text span {
    font-size: 11px;
    font-weight: 400;
    color: #CECECE;
    display: block;
}

.show-task {
    position: absolute;
    right: 0;
    display: block;
    z-index: 9999;
}

.task-notification.active .show-task {
    display: block;
}

.show-task ul {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #CECECE;
    background: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
    width: 250px;
    /* overflow-y: scroll; */
    max-height: 525px;
}

.show-task ul li {}

.show-task ul li a {
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    color: #0076F8;
    text-decoration: none;
}

.show-task ul li a span {
    font-size: 10px;
    font-weight: 400;
    color: #CECECE;
    display: block;
}

.show-task ul li:not(:last-child) {
    margin-bottom: 5x;
    padding-bottom: 5px;
    border-bottom: 1px solid #CECECE;
}

.profile-pic .dropdown-toggle:after {
    display: none;
}

.profile-pic .dropdown-menu {
    padding: 5px 15px;
    white-space: nowrap;
    border-radius: 5px;
}

.profile-pic .dropdown-menu a {
    font-weight: 600;
    padding: 7px 0px;
    color: #1E1E1E;
    font-size: 13px;
    display: block;
    text-decoration: none !important;
    background: transparent !important;
}

.profile-pic .dropdown-menu li:not(:last-child) {
    border-bottom: 1px solid #CECECE;
}

.current-notification-details p {
    font-size: 14px;
    color: #1E1E1E;
    font-weight: 400;
}
.current-notification-details {
    font-size: 14px;
    color: #1E1E1E;
    font-weight: 400;
    overflow-y: scroll;
    max-height: 450px;
}

@media screen and (max-width: 760px) {
    /* .header-row {
        display: flex;
        width: max-content;
    } */
 
    .no-default > img {
        width: 50px !important;
        height: 50px !important;
    }
    .profile-pic {
        margin-left: 3px;
    }
    .task-notification {
        margin-left: -5px;
    }
}