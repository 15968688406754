.tickets-tab-search {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.tickets-tab-search form {
  position: relative;
  width: 100%;
}

.tickets-tab-data {
  height: 285px;
  overflow-y: auto;
}

.tickets-tab-search form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.tickets-tab-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.tickets-btn button {
  margin-left: 15px;
}

.tickets-tab-search .add-icon-tickets {
  border-radius: 5px;
  background: #57a5fb;
  color: #fff;
  font-weight: 500;
  outline: none;
  border: 0px;
  padding: 7px 15px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.tickets-tab-search .add-icon-tickets img {
  margin-right: 10px;
}
.tickets-tab-search .add-icon-tickets {
  width: 134px;
}

.tickets-tab-label h3 {
  font-size: 16px;
  color: #57A5FB;
  font-weight: 800;
  margin-bottom: 0px;
}

.tickets-tab-data p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.edit-task-action-two {
  border: 0px;
  padding: 0px;
  background: transparent;
}

.tickets-tab-data label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.tickets-tab-data .row:not(:last-child) {
  margin-bottom: 10px;
}

.ticket-status-type {
  font-weight: 600;
}

.team-color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #ff9a00;
  margin: auto;
}

.attachment-ticket img {
  border-radius: 5px;
  border: 1px solid #cecece;
  width: 52px;
  height: 42px;
  object-fit: cover;
}

.tc-submit {
  border-radius: 10px;
  background: #3ebb11;
  padding: 8px 30px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: 0px;
  min-width: 150px;
  text-align: center;
}

.upload-area {
  display: flex;
  align-items: center;
}

.upload-area>*:not(:last-child) {
  margin-right: 15px;
}

.upload-loop {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #3ebb11;
  padding: 10px;
}

.upd-image img {
  width: 30px;
  border-radius: 10px;
}

.attached-upd-name {
  margin-bottom: 3px;
}

.upd-name {
  font-size: 11px;
  margin-left: 10px;
}

.file-upd-dw {
  color: #878787;
}

.file-upd-dw a {
  color: #1e1e1e;
  text-decoration: none;
}

.file-upd-dw a:hover {
  text-decoration: underline;
}

.upd-trash {
  margin-left: 10px;
}

.tickets-modal .modal-content {
  padding: 0px 20px 20px 20px;
  background: #e0eefe !important;
}

.tickets-modal .modal-body {
  padding: 20px;
  border-radius: 10px;
}

.tickets-modal .modal-header {
  border: 0px;
}

.edit-ticket-viewmore {
  z-index: 99999;
}

.add-ticket-viewmore {
  z-index: 99999;
}

.ticket-comments {
  background: rgb(255, 255, 255);
  padding: 7px;
  border-radius: 20px;
}
.all-comments {
  overflow: auto;
}

@media screen and (max-width: 760px) {
  .add-icon-tickets>img {
    width: 15px;
  }

  /* .modal-body > div:nth-child(3) .css-6ryl5x-control {
    width: 320px;
  }

  .modal-body > div:nth-child(4) .css-6ryl5x-control {
    width: 320px;
  }

  .modal-body > div:nth-child(6) .css-6ryl5x-control {
    width: 320px;
  }

  .modal-body > div:nth-child(7) .css-6ryl5x-control {
    width: 320px;
  } */

  /* .form-control {
    font-size: 10px;
  } */

  .tickets-tab-search form input {
    font-size: 10px;
  }

  .tickets-tab-search form button {
    width: 28px;
    height: 28px;
  }

  .chat-tab ul li button {
    font-size: 15px;
  }

  .tickets-tab-search .add-icon-tickets {
    padding: 5px 7px;
  }

  .tickets-tab-data .row>div:first-child {
    display: none;
  }

  .tickets-tab-data .row:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .tickets-tab-data .row {
    padding-right: 10px;
  }




  .tickets-tab-data .row>div:nth-child(-n+7) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }

  .tickets-tab-data .row>div:nth-child(2)::before {
    content: 'Subject: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 65px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(2) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(3)::before {
    content: 'Category: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 75px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(3) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(4)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 56px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(4) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(5)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(5) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(6)::before {
    content: 'Description: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 89px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(6) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(7)::before {
    content: 'Attachment: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 90px;
    color: #7B838A;
  }

  .tickets-tab-data .row>div:nth-child(7) {
    display: flex;
  }

  .tickets-tab-data .row>div:nth-child(8) {
    background: white;
    color: #57A5FB;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 50px;
    height: 40px;
    border: 2px solid #57A5FB;
    margin-left: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .tickets-tab-data .row>div:nth-child(8) img {
    width: 30px !important;
  }
  .ticket-search-btn{
    height: auto;
    width: 25px;
  }

  .ticket-data-row>div:before{
    max-width: 140px;
    width: 100%;
  }

  .ticket-data-row>div{
    display: flex;
  }
  .tickets-tab-data {
    height: 300px;
  }
  .ticket-new-select .css-oaim61-control{
    width: auto !important;
  }
  .task-ul li{
    justify-content: space-between;
  } 

}

.image-dash{
  min-height: 95px;
}

@media screen and (max-width: 575.98px) {
  .working-inner-two {
    padding: 10px;
  }
}