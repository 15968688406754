.side-menu {
    height: 100%;
    width: 340px;
    top: 0;
    left: -100%;
    background-color: #fff;
    position: fixed;
    z-index: 999;
    overflow-x: hidden;
    transition: .5s;
    padding: 20px 0px 20px 20px;
}

.nav-search {
    padding-right: 20px;
}

.nav-search form {
    position: relative;
}

.nav-search input {
    background: #EEEEEE !important;
    color: rgba(30, 30, 30, 0.50);
    font-size: 14px;
    letter-spacing: 0.42px;
    padding: 11px 10px 11px 45px;
    border: 0px;
    border-radius: 10px;
}

.nav-search button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    width: 43px;
    height: 43px;
    position: absolute;
    top: 0;
    left: 0;
}

.close-menu {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.menu-close {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    margin-right: 30px;
}

.nav-link {}

.nav-link>ul>li:not(:last-child) {
    margin-bottom: 15px;
}

.nav-link .nav-parent {
    border-radius: 28px 0px 0px 28px;
    background: #F5F5F5;
    padding: 5px;
    cursor: pointer;
}

.nav-link .nav-parent:hover {
    background: #EEEEEE;
}

.nav-parent a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: #1E1E1E;
}

.nav-parent {
    position: relative;
    display: flex;
    align-items: center;
}

.nav-parent:after {
    content: "";
    background: url(../../assests/images/right-angle.svg);
    width: 8px;
    height: 14px;
    position: absolute;
    right: 20px;
}

.open .nav-parent:after {
    background: url(../../assests/images/right-angle.svg);
    rotate: 90deg;
}

.nav-parent>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.nav-parent>img {
    margin-right: 15px;
}

.nav-child {
    margin: 15px 0px;
    padding-left: 75px;
}

.nav-child li:not(:last-child) {
    margin-bottom: 15px;
}

.vertical-dots {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    width: 30px;
    text-align: right;
}

.nav-child a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #1E1E1E;
}

.child-box {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 20px;
    justify-content: space-between;
}

.add-pro {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    position: relative;
    right: -12px;
}

.add-pro img {

    width: 30px;
    height: 30px;
}

.menu-action {
    border-radius: 5px;
    border: 1px solid #CECECE;
    background: #FFF;
    padding: 10px 15px;
    white-space: nowrap;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    right: 20px;
    z-index: 9;
    width: 100px;
    top: 100%;
}

.menu-action button {
    background: transparent;
    padding: 0px;
    color: #1E1E1E;
    outline: none;
    border: 0px;
    font-size: 13px;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: left;
}

.menu-action button+button {
    margin-top: 3px;
    padding-top: 3px;
    border-top: 1px solid #CECECE;
}

.menu-action button:hover,
.nav-parent a:hover,
.nav-child a:hover {
    color: #0076F8;
}

.side-menu.active {
    left: 0px;
}

.upload-file-project {
    border-radius: 8px;
    border: 2px dashed #D3D7DA;
    background: #FFF;
    padding: 15px;
    min-height: 140px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.box-file-hidded {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.upload-txt-project {
    font-size: 16px;
    color: #29353D;
    font-weight: 400;
    margin-bottom: 5px;
}

.upload-file-project label {
    font-size: 18px;
    color: #1D68A5;
    margin-bottom: 0px;
    cursor: pointer;
    display: inline;
    font-weight: 700;
}

.upload-file-project p {
    color: #29353D;
    font-size: 12px;
}

.modal-body .write-project-name input {
    font-size: 20px !important;
    border: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 0px !important;
}

.modal-body .add-project-label {
    color: #1E1E1E;
    font-weight: 550;
    font-size: 16px;
}


.add-milestone {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CECECE;
    padding-bottom: 5px;
}

.add-milestone label {
    margin-bottom: 0px;
}

.add-milestone button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    margin-left: 15px;
}

.modal-body .milestone-input {
    font-size: 18px !important;
    border: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


/* edit project */
.milestone-data {
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
}



.add-more-mile button {
    border-radius: 0px;
    background: transparent;
    padding: 0px;
    color: #57A5FB;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: 0px;
}

.add-more-mile img {
    vertical-align: unset;
}

.milestone-head {
    display: flex;
    align-items: center;
}

.milestone-head input {
    margin-right: 10px;
    width: auto;
    font-size: 18px !important;
    border: 0px !important;
    padding: 0px !important;
}

.edit-title {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
    border-radius: 0px;
}

.add-h3 {
    font-size: 18px !important;
    font-weight: 550 !important;
    margin-bottom: 0.5rem !important;
}

.status-label {
    color: #1E1E1E;
    font-size: 20px;
    font-weight: 550;
}

.close-right {
    display: flex;
    align-items: center;
}

.status-div {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.status-label {
    margin-right: 10px;
}

.status-dropdown .dropdown-menu {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 110px !important;
    min-width: auto;
}

.status-dropdown .dropdown-menu li {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.status-dropdown .dropdown-menu li:not(:last-child) {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #CECECE;
}

/* edit project */



@media (max-width: 760px) {
    /* .current-tasks-data-two label {
        display: none !important;
    } */
    .status-label {
        font-size: 14px;
    }
    .status-div .css-13cymwt-control {
        width: 180px;
        margin-top: 6px;
    }
}

/* @media (max-width: 991.98px) {
    .current-tasks-data-two .row>* {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
} */

.ql-snow.ql-toolbar button {
    width: 25px !important;
}

.selected-project {
    background-color: #57A5FB;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
}

.selected-project a {
    color: #fff;
}

.padding-css{
    padding-left: 10px;
}

.attachment-project {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #3ebb11;
    padding: 5px;
    height: 64px;
    width: fit-content;
    margin-bottom: 10px;
  }
  .attachment-project-image img {
    height: 55px;
    border-radius: 10px;
  }