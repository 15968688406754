@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.user-managemanet-inner {
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
}

.user-managemanet-inner-viewmore {
  border-radius: 20px 20px 20px 20px;
  padding: 20px;
}

.manage-users-tab-search {
  /* border-bottom: 1px solid #CECECE; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.view-line {
  border-bottom: 1px solid #cecece;
}

.manage-users-tab-search form {
  position: relative;
  width: 100%;
}

.manage-users-tab-search form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.manage-users-tab-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.manage-users-tab-search .add-icon-two,
.manage-users-tab-search .filter-icon-two {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.manage-users-tab-label h3 {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  margin-bottom: 0px;
}

.manage-users-tab-data {
  height: 230px;
  overflow-y: auto;
}

.manage-users-tab-data::-webkit-scrollbar {
  width: 5px;
}

.manage-users-tab-data::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.manage-users-tab-data::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.manage-users-tab-data-viewmore {
  height: 300px;
  overflow-y: auto;
}

.manage-users-tab-data-viewmore::-webkit-scrollbar {
  width: 5px;
}

.manage-users-tab-data-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.manage-users-tab-data-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.manage-users-tab-data p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.manage-users-tab-data-viewmore p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.edit-task-action-two {
  border: 0px;
  padding: 0px;
  background: transparent;
}

.user-avatar img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.manage-users-tab-data label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.manage-users-tab-data .row:not(:last-child) {
  margin-bottom: 10px;
}

.status-type {
  padding: 5px 15px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
}

.user-action {
  border: 0px;
  padding: 0px;
  background: transparent;
}

.user-action-col {
  position: relative;
}

.user-action-data {
  border-radius: 5px;
  border: 1px solid #cecece;
  background: #fff;
  padding: 10px 15px;
  white-space: nowrap;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
  min-width: auto;
}

.user-action-data button {
  background: transparent;
  font-size: 14px;
  padding: 0px;
  outline: none;
  border: 0px;
  color: #0076f8;
  display: block;
  width: 100%;
  text-align: left;
}

.user-action:after {
  content: none;
}

.user-action-data button+button {
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #cecece;
}

/* @media (min-width: 992px) {
    .manage-users-tab-data label {
        display: none !important;
    }
} */
/* 
@media (max-width: 991.98px) {
  .manage-users-tab-data .row > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
} */

.manage-roles-tab-search {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.manage-roles-tab-search form {
  position: relative;
  width: 100%;
}

.manage-roles-tab-search form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.manage-roles-tab-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.manage-roles-tab-search .add-icon-two,
.manage-roles-tab-search .filter-icon-two {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.add-role {
  background: transparent;
  padding: 0px;
  outline: none;
  border: none;
}

.delete-roles {
  background: transparent;
  padding: 0px;
  outline: none;
  border: none;
}

.del-role {
  background: transparent;
  /* padding: 0px; */
  /* outline: none; */
  border: 0px;
  width: 28px;
  /* font-size: 10px; */
}

.manage-roles-tab-label h3 {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  margin-bottom: 0px;
}

.manage-roles-tab-data p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.manage-roles-tab-data-viewmore p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.manage-roles-tab-data {
  height: 230px;
  overflow-y: auto;
}

.manage-roles-tab-data::-webkit-scrollbar {
  width: 5px;
}

.manage-roles-tab-data::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.manage-roles-tab-data::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.manage-roles-tab-data-viewmore {
  height: 300px;
  overflow-y: auto;
}

.manage-roles-tab-data-viewmore::-webkit-scrollbar {
  width: 5px;
}

.manage-roles-tab-data-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.manage-roles-tab-data-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.manage-roles-tab-data label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.manage-roles-tab-data .row:not(:last-child) {
  margin-bottom: 10px;
}

.access-to {
  display: flex;
  flex-flow: wrap;
}

.access-to li {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 4px 10px;
  border-radius: 4px;
  background: #959595;
  margin-bottom: 5px;
}

.access-to li {
  margin-right: 10px;
}

.access-to li button {
  margin-left: 10px;
}

.access-to button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.delete-role img {
  width: 10px;
}

.role-data {
  white-space: nowrap;
  /*    padding: 0px 35px;*/
}

/* .role-data {
    border-radius: 5px;
    border: 1px solid #CECECE;
    background: #FFF;
    padding: 10px 15px;
    white-space: nowrap;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    right: 80px;
} */

.role-data ul {
  padding: 10px !important;
  width: 100%;
}

.role-data ul li {
  color: #0076f8;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.role-data ul li label {
  cursor: pointer;
  color: #0076f8;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.role-data ul li .form-check-input {
  cursor: pointer;
}

.role-data ul li+li {
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #cecece;
}

.form-check-input {
  margin-top: 0px;
  margin-right: 7px;
  width: 16px;
  height: 16px;
  border-color: #7b838a;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #7b838a;
  border-color: #cecece !important;
}

.role-data .dropdown-toggle {
  border: 1px solid #555;
  background: transparent !important;
  color: #1e1e1e !important;
  box-shadow: none !important;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 7px 10px !important;
}

.role-data .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.delete-btn button+button {
  margin-left: 15px;
}

.delete-user-role .modal-body h3 {
  font-size: 16px;
  font-weight: 400;
}

.delete-user-role .modal-body h3 span {
  font-size: 18px;
  font-weight: 700;
}

/* 
@media (min-width: 992px) {
  .manage-roles-tab-data label:not(.form-check-label) {
    display: none !important;
  }
} */

/* @media (max-width: 991.98px) {
  .manage-roles-tab-data .row > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
} */

.subscription-billing-tab {
  border-top: 1px solid #cecece;
  padding-top: 10px;
  margin-top: 15px;
}

.subscription-billing-tab-label h3 {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  margin-bottom: 0px;
}

.subscription-billing-tab-data p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.subscription-billing-tab-data label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.subscription-billing-tab-data .row:not(:last-child) {
  margin-bottom: 10px;
}

.subscription-billing-tab-data {
  height: 243px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.subscription-billing-tab-data::-webkit-scrollbar {
  width: 5px;
}

.subscription-billing-tab-data::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.subscription-billing-tab-data::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.subscription-billing-tab-data-viewmore {
  /* height: 100vh !important; */
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.subscription-billing-tab-data-viewmore::-webkit-scrollbar {
  width: 5px;
}

.subscription-billing-tab-data-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.subscription-billing-tab-data-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.user-action {
  border: 0px;
  padding: 0px;
  background: transparent;
  outline: none;
}

.payment-due {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  color: #0076f8;
}

/* @media (min-width: 992px) {
  .subscription-billing-tab-data label {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .subscription-billing-tab-data .row > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
} */

.plans-section {
  border-top: 1px solid #cecece;
  padding-top: 10px;
  margin-top: 15px;
}

.plans-label h3 {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  margin-bottom: 0px;
}

.plan-title {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.plan-features span {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #0076f8;
  padding: 8px 15px 8px 15px;
  display: inline-block;
  width: 80px;
}

.plans-data .row:not(:last-child) {
  margin-bottom: 10px;
}

.plans-data {
  height: 280px;
  overflow-y: auto;
}

.plans-data-viewmore {
  height: 300px;
  overflow-y: auto;
}

.nav-link {
  font-size: 16px;
  color: #362464;
  font-weight: 600;
  display: block;
}

.chat-tab {
  margin-bottom: 10px;
}

.chat-tab ul li:not(:last-child) {
  margin-right: 15px;
}

.chat-tab ul li button {
  padding: 0px 5px;
  color: #362460 !important;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
}

.chat-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-list ul li:not(:last-child) {
  margin-bottom: 10px;
}

.chat-left {
  display: flex;
  align-items: center;
}

.short-name {
  margin-right: 10px;
  display: flex;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.full-name h3 {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1px;
}

.last-seen {
  color: #1e1e1e;
  font-size: 11px;
  font-weight: 400;
}

.chat-right {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: normal;
}

.chat-search {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.chat-search form {
  position: relative;
  width: 100%;
}

.chat-search form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.chat-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.chat-search .filter-icon-two {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.chat-search .add-icon-two {
  border-radius: 5px;
  background: #57A5FB;
  color: #fff;
  font-weight: 500;
  outline: none;
  border: 0px;
  margin-left: 15px;
  padding: 7px 15px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.chat-search .add-icon-two img {
  margin-right: 10px;
}

.chat-btn button {
  margin-left: 15px;
}

.user-tab {
  margin-bottom: 10px;
}

.user-tab ul li:not(:last-child) {
  margin-right: 15px;
}

.user-tab ul li button {
  padding: 0px 5px;
  color: #362460 !important;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
}

.user-tab ul li.active {
  border-bottom: 2px solid #7257ae;
}

.users-filter h3 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #dadada;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.users-filter ul li {
  font-size: 14px;
}

.users-filter ul li:not(:last-child) {
  margin-bottom: 3px;
}

.users-filter .dropdown-toggle::after {
  display: none;
}

.users-filter .dropdown-menu {
  padding: 12px;
  white-space: nowrap;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

/* Add New User Subscription Details */

.subscription {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 12px;
  margin-bottom: 0px;
}

.subscription-div {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 8px;
  margin-bottom: 0px;
}

.subscription-input {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
  margin-bottom: 0px;
}

.user-tab button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

@media screen and (max-width: 760px) {

  .user-managemanet-inner {
    border-radius: 20px;
  }

  .user-tab ul {
    width: 85%;
  }

  .user-tab ul li button {
    color: #362460 !important;
    font-size: 14px;
    border-bottom: 1px solid transparent;
  }

  /* 
  .user-data-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 321px;
  } */

  .manage-users-tab-data>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .manage-users-tab-data .row {
    margin-right: 15px;
  }

  .manage-users-tab-data-viewmore>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .manage-users-tab-data-viewmore .row {
    margin-right: 15px;
  }

  .user-data-row>div:last-child {
    margin-bottom: 15px;
  }

  .user-data-row>div:not(:first-child):not(:last-child) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
    word-break: break-all;
  }

  .user-data-row>div:nth-child(2)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .user-data-row>div:nth-child(1) {
    width: auto;
    margin-left: 5px;
  }

  .user-action>img {
    width: 20px;
  }

  .user-action>img {
    width: 15px;
    margin-left: 10px;
  }

  .user-data-row>div:last-child {
    background: #57a5fb;
    color: black;
    border-radius: 5px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    width: 40px;
    height: 30px;
    /* border-bottom: 2px solid #000; */
    margin-left: 2px;
    margin-top: 10px;
  }

  .user-data-row div>p {
    font-size: 15px;
  }

  .manage-users-tab-data .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-users-tab-data-viewmore .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-users-tab-search .add-icon-two>img {
    width: 30px;
  }

  .user-data-row>div:nth-child(2)::before {
    content: 'Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
  }

  .user-data-row>div:nth-child(2) {
    display: flex;
  }

  .user-data-row>div:nth-child(3)::before {
    content: 'Email ID: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 69px;
    color: #7B838A;
  }

  .user-data-row>div:nth-child(3) {
    display: flex;
  }

  .user-data-row>div:nth-child(4)::before {
    content: 'Role: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 43px;
    color: #7B838A;
  }

  .user-data-row>div:nth-child(4) {
    display: flex;
  }

  .user-data-row>div:nth-child(5)::before {
    content: 'Company: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 80px;
    color: #7B838A;
  }

  .user-data-row>div:nth-child(5) {
    display: flex;
  }

  .user-data-row>div:nth-child(6)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 57px;
    color: #7B838A;
  }

  .user-data-row>div:nth-child(6) {
    display: flex;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(2)::before {
    content: 'Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 47px;
    color: #7B838A;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(2) {
    display: flex;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(3)::before {
    content: 'Email ID: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 67px;
    color: #7B838A;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(3) {
    display: flex;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(4)::before {
    content: 'Role: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 42px;
    color: #7B838A;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(4) {
    display: flex;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(5)::before {
    content: 'Company: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(5) {
    display: flex;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(6)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 54px;
    color: #7B838A;
  }

  .manage-users-tab-data-viewmore .user-data-row>div:nth-child(6) {
    display: flex;
  }

  .manage-users-tab-search form input {
    font-size: 12px;
  }

  .manage-users-tab-search form {
    position: relative;
    width: 75%;
  }

  .manage-users-tab-search form button {
    width: 35px;
    height: 33px;
  }

  /* //subscription mobile view */
  .subscription-billing-tab-data>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .subscription-billing-tab-data .row {
    margin-right: 15px;
  }

  /* .manage-users-tab-data-viewmore > div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  } */

  /* .manage-users-tab-data-viewmore .row {
    margin-right: 15px;
  } */

  .subscription-data-row>div:last-child {
    margin-bottom: 15px;
  }

  .subscription-data-row>div:not(:last-child) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
    word-break: break-all;
  }

  .subscription-data-row>div:nth-child(2)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .subscription-data-row>div:nth-child(1) {
    /* width: auto; */
    /* margin-left: 5px; */
  }

  .user-action>img {
    width: 20px;
  }

  .user-action>img {
    width: 15px;
    margin-left: 10px;
  }

  .subscription-data-row>div:last-child {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 40px;
    height: 30px;
    border-bottom: 2px solid #000;
    margin-left: 2px;
    margin-top: 10px;
  }

  .subscription-data-row>div:last-child::before {
    /* content: 'Edit'; */
    /* font-weight: 600; */
    /* font-size: 15px; */
    /* min-width: 52px; */
    color: #7B838A;
  }

  .subscription-data-row>div:last-child {
    display: flex;
  }

  .subscription-data-row div>p {
    font-size: 15px;
  }

  .subscription-billing-tab-data .row:not(:last-child) {
    margin-bottom: 15px;
  }

  /* .manage-users-tab-data-viewmore .row:not(:last-child) {
    margin-bottom: 15px;
  } */

  .manage-users-tab-search .add-icon-two>img {
    width: 30px;
  }

  .subscription-data-row>div:nth-child(1)::before {
    content: 'Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
  }

  .subscription-data-row>div:nth-child(1) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(2)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(2) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(3)::before {
    content: 'Users:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 0px;
  }

  .subscription-data-row>div:nth-child(3) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(4)::before {
    content: 'Projects:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;

  }

  .subscription-data-row>div:nth-child(4) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(5)::before {
    content: 'Storage:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(5) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(6)::before {
    content: 'Monthly Fees: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 57px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(6) {
    display: flex;
  }

  /* second subscription  */


  /* .subscription-data-row > div:last-child {
    margin-bottom: 15px;
  } */

  .subscription-data-row-second>div {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
    word-break: break-all;
  }

  .subscription-data-row>div:nth-child(1) {
    /* width: auto; */
    /* margin-left: 5px; */
  }

  .subscription-data-row-second div>p {
    font-size: 15px;
  }

  .subscription-billing-tab-data .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-users-tab-search .add-icon-two>img {
    width: 30px;
  }

  .subscription-data-row-second>div:nth-child(1)::before {
    content: 'Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
  }

  .subscription-data-row-second>div:nth-child(1) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(2)::before {
    content: 'Description: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row-second>div:nth-child(2) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(3)::before {
    content: 'Amount:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row-second>div:nth-child(3) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(4)::before {
    content: 'Payment Status:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;

  }

  .subscription-data-row-second>div:nth-child(4) {
    display: flex;
  }

  .subscription-data-row-second .row>div:nth-child(5) {
    background: white;
    color: #57A5FB;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 50px;
    height: 40px;
    border-bottom: 2px solid #57A5FB;
    margin-left: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .billing-status-mobile {
    background: #FFF;
    color: #0076F8;
    display: inline-flex;
    padding: 5px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }

  .subscription-billing-tab-label .title-billing h4 {
    font-size: 16px;
    color: #362460;
    font-weight: 800;
    margin-bottom: 0px;
  }

  .manage-roles-tab-data {
    height: 255px;
    overflow-y: auto;
  }

  .subscription-billing-tab-data {
    height: 293px;
    overflow-y: auto;
  }

  .manage-users-tab-data {
    height: 315px;
    overflow-y: auto;
  }

  /* .role-data .dropdown .css-b62m3t-container {
    width: auto !important;
  } */
  .atc-dropdown .css-b62m3t-container .css-13cymwt-control {
    width: auto !important;
  }

  .user-data-row>div:before {
    max-width: 120px;
    width: 100%;
  }

  .user-data-row>div {
    display: flex;
  }

  .subscription-data-row>div:before {
    max-width: 120px;
    width: 100%;
  }

  .subscription-data-row>div {
    display: flex;
  }

  .subscription-data-row-second>div:before {
    max-width: 140px;
    width: 100%;
  }

  .subscription-data-row-second>div {
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .manage-users-tab-label>div {
    display: grid;
    /* grid-template-columns: 4rem 7rem 12rem 4rem 7rem 6rem 2rem; */
    grid-template-columns: 0.7fr 1.6fr 3.1fr 0.6fr 1.4fr 0.8fr 0.5fr;
    word-wrap: break-word;
  }

  .manage-users-tab-data>div {
    display: grid;
    /* grid-template-columns: 4rem 7rem 12rem 4rem 7rem 6rem 2rem; */
    grid-template-columns: 0.7fr 1.6fr 3.1fr 0.6fr 1.4fr 0.8fr 0.5fr;
    word-wrap: break-word;
  }

  .user-tab ul li button {
    font-size: 15px;
  }

  .subscription-data-row>div:before {
    max-width: 360px;
    width: 100%;
  }

  .subscription-data-row>div {
    display: flex;
  }

  .subscription-data-row>div:last-child {
    margin-bottom: 15px;
  }

  .subscription-data-row>div:not(:last-child) {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
    word-break: break-all;
  }

  .subscription-data-row>div:nth-child(2)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .subscription-data-row>div:nth-child(1) {
    /* width: auto; */
    /* margin-left: 5px; */
  }

  .user-action>img {
    width: 20px;
  }

  .user-action>img {
    width: 15px;
    margin-left: 10px;
  }

  .subscription-data-row>div:last-child {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 40px;
    height: 30px;
    border-bottom: 2px solid #000;
    margin-left: 2px;
    margin-top: 10px;
  }

  .subscription-data-row>div:last-child::before {
    /* content: 'Edit'; */
    /* font-weight: 600; */
    /* font-size: 15px; */
    /* min-width: 52px; */
    color: #7B838A;
  }

  .subscription-data-row>div:last-child {
    display: flex;
  }

  .subscription-data-row div>p {
    font-size: 15px;
  }

  .subscription-billing-tab-data .row:not(:last-child) {
    margin-bottom: 15px;
  }

  /* .manage-users-tab-data-viewmore .row:not(:last-child) {
    margin-bottom: 15px;
  } */

  .manage-users-tab-search .add-icon-two>img {
    width: 30px;
  }

  .subscription-data-row>div:nth-child(1)::before {
    content: 'Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
  }

  .subscription-data-row>div:nth-child(1) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(2)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(2) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(3)::before {
    content: 'Users:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 0px;
  }

  .subscription-data-row>div:nth-child(3) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(4)::before {
    content: 'Projects:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;

  }

  .subscription-data-row>div:nth-child(4) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(5)::before {
    content: 'Storage:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(5) {
    display: flex;
  }

  .subscription-data-row>div:nth-child(6)::before {
    content: 'Monthly Fees: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 57px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row>div:nth-child(6) {
    display: flex;
  }

  /* second subscription  */


  /* .subscription-data-row > div:last-child {
    margin-bottom: 15px;
  } */

  .subscription-data-row-second>div {
    background-color: #dbe7ff;
    border: 2px solid #57a5fb;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
    word-break: break-all;
  }

  .subscription-data-row>div:nth-child(1) {
    /* width: auto; */
    /* margin-left: 5px; */
  }

  .subscription-data-row-second div>p {
    font-size: 15px;
  }

  .subscription-billing-tab-data .row:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-users-tab-search .add-icon-two>img {
    width: 30px;
  }

  .subscription-data-row-second>div:nth-child(1)::before {
    content: 'Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
  }

  .subscription-data-row-second>div:nth-child(1) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(2)::before {
    content: 'Description: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row-second>div:nth-child(2) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(3)::before {
    content: 'Amount:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;
  }

  .subscription-data-row-second>div:nth-child(3) {
    display: flex;
  }

  .subscription-data-row-second>div:nth-child(4)::before {
    content: 'Payment Status:';
    font-weight: 600;
    font-size: 15px;
    min-width: 52px;
    color: #7B838A;
    margin-right: 10px;

  }

  .subscription-data-row-second>div:nth-child(4) {
    display: flex;
  }

  .subscription-data-row-second .row>div:nth-child(5) {
    background: white;
    color: #57A5FB;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 50px;
    height: 40px;
    border-bottom: 2px solid #57A5FB;
    margin-left: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .billing-status-mobile {
    background: #FFF;
    color: #0076F8;
    display: inline-flex;
    padding: 5px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }

  .subscription-billing-tab-label .title-billing h4 {
    font-size: 16px;
    color: #362460;
    font-weight: 800;
    margin-bottom: 0px;
  }

  .subscription-data-row-second>div:before {
    max-width: 360px;
    width: 100%;
  }

  .subscription-data-row-second>div {
    display: flex;
  }


}

.user-update {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

.add-text {
  color: #1E1E1E;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 7px;
}

.add-text-2 {
  color: #1E1E1E;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
}

.dec-btn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid #57A5FB;
  background-color: white;
  color: #57A5FB;
  margin-bottom: 20px;
}

.input-btn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid #57A5FB;
  background-color: white;
  color: #57A5FB;
  margin-bottom: 20px;
  text-align: center;
}

.counter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.billing-status {
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #57A5FB;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.col-lg-2 p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-lg-3 p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}