.chat-tickets-inner {
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
}

.chat-tickets-inner-viewmore {
  padding: 20px;
  border-radius: 20px 20px 20px 20px;
}


.noty-icon {
  width: 18px;
  height: 18px;
  background: #F13A3A;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -3px;
  right: -3px;
}

.chat-tab {
  margin-bottom: 10px;
}

.chat-tab ul li:not(:last-child) {
  margin-right: 15px;
}

.chat-tab ul li button {
  padding: 0px 5px;
  color: #362460 !important;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  border-bottom: 2px solid transparent;
}

.chat-tab ul li button.active {
  border-bottom: 2px solid #7257ae;
}

.chat-list {
  height: 320px;
  overflow-y: auto;
}

.chat-list::-webkit-scrollbar {
  width: 5px;
}

.chat-list::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.chat-list::-webkit-scrollbar-thumb {
  background-color: #57A5FB;
  height: auto;
}

.chat-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-list ul li:not(:last-child) {
  margin-bottom: 10px;
}

.chat-left {
  display: flex;
  align-items: center;
}

.short-name {
  margin-right: 10px;
  display: flex;
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.full-name h3 {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1px;
}

.last-seen {
  color: #1E1E1E;
  font-size: 11px;
  font-weight: 400;
}

.chat-right {
  border-radius: 4px;
  width: 24px;
  margin-right: 10px;
  height: 24px;
  color: #FFF;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: normal;
}

.chat-right-msg {
  border-radius: 4px;
  margin-right: 10px;
  font-size: 13px;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: normal;
}

.chat-search {
  /* border-bottom: 1px solid #CECECE; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.view-line {
  border-bottom: 1px solid #CECECE;
}


.chat-search form {
  position: relative;
  width: 100%;
}

.chat-search form input {
  border-color: #CECECE;
  color: #1E1E1E;
}

.chat-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.chat-btn button {
  margin-left: 15px;
}

/* // css added for */

/* viewchats.css */
.chat-container {
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 20px auto;
  padding: 20px;
}

.chat-messages {
  max-height: 300px;
  overflow-y: auto;
}

.chat-message {
  margin-bottom: 10px;
}

.sender-label {
  font-weight: bold;
  margin-right: 8px;
}

.message-text {
  word-wrap: break-word;
}

.message-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-field {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.send-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

.actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.action-button {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}


.team-write-chat {
  display: flex;
  align-items: center;
}

.team-chat-inner {
  position: relative;
  width: 100%;
}

.team-chat-inner .form-control {
  padding-right: 50px;
}

.team-chat-inner .form-control {
  border-radius: 10px;
  border: 1px solid #CECECE;
  color: #1E1E1E;
  font-size: 14px;
  padding: 9px 10px;
}

.team-send-chat {
  margin-left: 10px;
}


.chat-conversation {
  max-height: 208px;
  min-height: 208px;
  padding-right: 10px;
  overflow-y: scroll;
}

.msg.sent {
  float: right;
  text-align: right;
}

.msg.received {
  float: left;
}

.msg.sent .msg-txt {
  background: #C0C0C0;
}

.msg.received .msg-txt {
  background: #DEDEDE;
}

.msg-time {
  color: #C6C6C6;
  margin-top: 3px;
  font-size: 10px;
}

.msg {
  max-width: 85%;
  clear: both;
  margin: 5px 0px;
}


.msg-txt {
  display: inline-block;
  font-size: 13px;
  padding: 6px 10px;
  border-radius: 4px;
  word-wrap: break-word;
}

.msg-date {
  width: 100%;
  float: left;
  clear: both;
  text-align: center;
}


.msg-date span {
  border-radius: 2px;
  background: rgba(54, 36, 96, 0.10);
  font-size: 10px;
  padding: 2px 10px;
}

.chat-head {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #CECECE;
}

.chat-head h2 {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #362460;
  margin-bottom: 0px;
}

.chat-tab button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.chat-head button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.team-write-chat button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.back-btn>img {
  width: 25px;
}

@media screen and (max-width: 760px) {

  .chat-tickets-inner {
    border-radius: 20px;
  }

  .chat-and-tickets {
    width: -webkit-fill-available;
  }

  .back-btn>img {
    width: 20px;
  }

  .chat-list {
    height: 300px;
  }
}

@media screen and (max-width: 575.98px) {
  .chat-tickets-inner {
    padding: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .chat-and-tickets {
    width: -webkit-fill-available;
  }
}