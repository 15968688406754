/* body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: #1e1e1e;
  background: rgba(0, 118, 248, 0.1);
} */

.find-files {
  height: 420px;
}

.dots {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 8px;
}

.view-more {
  border-radius: 0px 0px 20px 20px;
  /* background: #7257ae; */
  padding: 3px;
  text-align: center;
  color: #fff;
  width: 100%;
  outline: none !important;
  border: 0px;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 600;
  /* height: 500px; */
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  box-shadow: none;
  /*border-color: initial;*/
}

.drag-me {
  cursor: move !important;
}

/* .overlay {
  background: rgba(30, 30, 30, 0.35);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  display: none;
}

.overlay.active {
  display: block;
} */

/* .no-default {
  background: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
  box-shadow: none !important;
} */

/* common */

.btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  background: transparent;
  padding: 0px !important;
  outline: none;
  border: 0px;
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  margin: 0px !important;
}

.modal-content {
  border-radius: 20px;
  padding: 0px 20px;
}

.modal-header {
  padding: 15px 0px;
}

.modal-body {
  padding: 20px 0px 20px 0px;
}

.modal-footer {
  border: 0px;
  padding: 0px 0px 20px 0px;
}

.modal-footer button {
  margin: 0px;
}
.modal-footer button + button {
  margin-left: 30px;
}

.border-btn {
  border-radius: 10px;
  padding: 6px 25px;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  background: transparent;
  color: #57A5FB;
  border: 2px solid #57A5FB;
  min-width: 150px;
  text-align: center;
}
.bg-btn {
  border-radius: 10px;
  background: #57A5FB;
  padding: 8px 25px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: 0px;
  min-width: 150px;
  text-align: center;
}

.modal-body label {
  color: #1e1e1e;
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
}

.modal-body label span {
  font-size: 10px;
}

.modal-body .form-control,
.modal-body .form-select {
  border-radius: 10px;
  border: 1px solid #555;
  color: #1e1e1e;
  font-size: 14px;
  padding: 9px 10px;
  font-weight: 500;
}

.form-control::placeholder {
  opacity: 0.5;
}

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.modal-body h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.modal-body p {
  font-size: 14px;
}

/* common */

/*------------------------------------
      Tagsinput
    ------------------------------------*/
.u-tagsinput .bootstrap-tagsinput {
  width: 100%;
  border-color: #e7eaf3;
  border-radius: 0.3125rem;
  box-shadow: none;
  padding: 0.25rem;
  padding-bottom: 0;
}

.u-tagsinput .bootstrap-tagsinput::before {
  content: "|";
  display: inline-block;
  width: 1px;
  line-height: 1;
  font-size: 0.625rem;
  opacity: 0;
  padding: 0.75rem 0;
}

.u-tagsinput .bootstrap-tagsinput .tag {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
  border-radius: 0.3125rem;
  padding: 0.25rem 1.875rem 0.25rem 0.75rem;
  margin-bottom: 0.25rem;
  margin-right: 0;
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"] {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  color: #77838f;
  font-size: 1.25rem;
  outline: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]::after {
  content: "\00d7";
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]:hover {
  color: #1e2022;
  box-shadow: none;
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  box-shadow: none;
}

.no-default {
  background: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
  box-shadow: none !important;
}

.files-inner {
  /* border-radius: 20px; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  height: 380px;

}
.files-inner-viewmore {
  /* border-radius: 20px; */
  border-radius: 20px 20px 20px 20px;
  padding: 20px;
  height: 450px;

}

.upload-file {
  border-radius: 8px;
  border: 2px dashed var(--2-grey, #d3d7da);
  background: #fff;
  padding: 15px;
  height: 220px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.box-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-txt {
  font-size: 16px;
  color: #29353d;
  font-weight: 400;
  margin-bottom: 10px;
}
.upload-file label {
  font-size: 18px;
  color: #1d68a5;
  margin-bottom: 0px;
  cursor: pointer;
  display: inline;
  font-weight: 700;
}
.upload-file p {
  color: #29353d;
  font-size: 12px;
}

.file-search {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.file-search h2 {
  font-size: 20px;
  font-weight: 600;
  color: #362460;
  margin-bottom: 0px;
}

.file-search form {
  position: relative;
  width: 100%;
}

.file-search form input {
  border-color: #cecece;
  color: #1e1e1e;
  font-size: 16px;
}

.file-search form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.file-style {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.file-style button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}
.file-style button + button {
  margin-left: 10px;
}
.file-folder {
  text-align: center;
  height: 270px;
  overflow-y: auto;
}
.file-folder .row > * {
  margin: 10px 0px;
}

.file-folder::-webkit-scrollbar {
  /* background-color: #57A5FB; 
  height: auto;
  width: 3px; */
  display: none;
}

.file-style button.active path,
.file-style button.active rect {
  fill: #7257ae;
}

.file-name {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #1e1e1e;
}

.text-limit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.folder-type {
  padding: 0px 10px;
  position: relative;
  border: 2px solid #604799;
  height: 115px;
  width: 114px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  position: relative;
}

.file-type {
  height: 130px;
}

.folder-type-file {
  padding: 15px 10px;
  /* position: relative; */
  border: 2px solid #604799;
  height: 130px;
  width: 114px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  position: relative;
}

.file-image {
  height: 64px;
}

.file-body {
  max-height: 270px;
  margin-bottom: 5px;
}

.file-image img {
  max-width: 100%;
}

.list-item:hover {
  transform: scale(1.03);
  color: #7B838A;
  font-weight: 400;
}

.tooltip_element {
  position: relative;
}

.tooltip {
  position: absolute;
  /* background-color: #57A5FB; */
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 1s;
  top: 50px;
  left: 10px;
  width: max-content;
}

.tooltip_element:hover .tooltip {
  opacity: 1;
}

.multi-select {
  width: 458px;
}

.folders {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-width: fit-content;
}

.documents button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.folder-name {
  font-size: 20px;
  font-weight: 600;
}

@media  screen and (max-width: 760px) {
  .files-inner{
    border-radius: 20px;
    height: 425px;
  }
    .folders {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .file-image {
      height: 40px;
    }

    .file-image img {
      width: 70px;
    }
    .file-style img{
      /* max-width: 75%; */
      width: 30px;
    }

    .folder-type {
      padding: 0 5px;
      position: relative;
      border: 2px solid #604799;
      /* height: 80px; */
      /* width: 80px; */
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      position: relative;
    }

    .folder-type {
      height: 120px;
      width: 100px;
    }

    .dots {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      margin-right: 10px;
    }

    .dots img {
      height: 13px;
    }

    .file-search h2 {
      font-size: 15px;
    }

    .documents-header {
      display: flex;
      justify-content: end;
    }

    .find-files {
      width: -webkit-fill-available;
    }

    .col-lg-8 {
      width: 50%;
      margin-top: -25px;
    }

    /* .col-lg-4 {
      width: 50%;
    } */

    .column-css{
      width: 50%;
    }

    .modal-body > .col-md-12 .css-13cymwt-control {
      width: 359px !important;

    }

    .modal-body > .col-md-12 .css-t3ipsp-control {
      width: 359px !important;
    }

    .modal-body > .col-md-12 .css-1nmdiq5-menu {
      width: 359px !important;
    }

    .modal-body form > .row > .col-md-12 .css-13cymwt-control {
      width: 359px !important;

    }

    .modal-body form > .row > .col-md-12 .css-t3ipsp-control {
      width: 359px !important;
    }

    .modal-body form > .row > .col-md-12 .css-1nmdiq5-menu {
      width: 359px !important;
    }

    .bg-btn-mobile {     
      min-width: 120px;
    }

    .multi-select .css-z7hvjq-control {
      width: auto;
    }
    .file-folder {
      height: 300px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
    .find-files {
      width: -webkit-fill-available;
    }

    .col-lg-8 {
      width: 50%;
    }

    /* .col-lg-4 {
      width: 50%;
    } */
    .column-css{
      width: 50%;
    }

    .folder-type {
      padding: 0 5px;
      width: 100px;
    }
}

.dots-icon {
  opacity: 0; /* Initially hidden */
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Show the three dots icon when hovering over the file container */
div:hover .dots-icon {
  opacity: 1; /* Show the icon on hover */
}
