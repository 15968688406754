.signin-btn {
    border-radius: 10px;
    background: #362460;
    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
    padding: 10px 60px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border: 0px;
    outline: none;
    display: flex;
    margin: auto;
    justify-content: center;
    width: auto;
}